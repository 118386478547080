import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Button from '../../../../../../../components/Button'
import TournamentMatchCard from '../../../../../../../components/TournamentMatchCard'
import {
    autoGenerateGrandPrixDraw,
    getGroupMatches,
} from '../../../../../../../lib/api'
import { useAppContext } from '../../../../../../../lib/contextLib'

const GroupMatches = ({
    drawToShow,
    tournament,
    setUpdateMatches,
    updateMatches,
    isCreateMatchesButtonVisible,
    setIsCreateMatchesButtonVisible,
    totalPlayers,
}) => {
    const { user, setSgpDataForPDF } = useAppContext()
    const [groupMatches, setGroupMatches] = useState([])

    const hasAllLoserParticipantUUID = (data) =>
        Array.isArray(data) &&
        data.length > 0 &&
        data.every(
            (item) =>
                item.attributes &&
                item.attributes.loser_participant_uuid != null
        )

    const hasAllFullMatches = (data) =>
        Array.isArray(data) &&
        data.length > 0 &&
        data.every(
            (item) =>
                item.attributes &&
                item.attributes.side_1_uuid != null &&
                item.attributes.side_2_uuid != null
        )

    const hasAllMatches = () => {
        if (
            groupMatches?.length >= Math.ceil(+totalPlayers / 2) &&
            hasAllFullMatches(groupMatches)
        ) {
            return true
        } else {
            return false
        }
    }

    const handleGetGroupMatches = async () => {
        if (drawToShow?.round_number && drawToShow?.subtournament_name) {
            try {
                const groupMatchesData = await getGroupMatches({
                    tournament_uuid: tournament?.attributes?.uuid,
                    is_double: drawToShow?.is_double,
                    gender: { gender_name: drawToShow?.gender?.gender_name },
                    draw_type: {
                        draw_type_name: drawToShow?.draw_type?.draw_type_name,
                    },
                    round_number: drawToShow?.round_number,
                    subtournament_name:
                        drawToShow?.round_number === 5
                            ? null
                            : drawToShow?.subtournament_name,
                })

                if (groupMatchesData) {
                    setGroupMatches(groupMatchesData)

                    let { round_number, ...rest } = drawToShow
                    setSgpDataForPDF((prev) => {
                        const existingIndex = prev.groupMatches.findIndex(
                            (item) =>
                                item?.round_number === drawToShow?.round_number
                        )
                        let updatedGroupMatches
                        if (existingIndex > -1) {
                            updatedGroupMatches = [...prev.groupMatches]
                            updatedGroupMatches[existingIndex] = {
                                round_number: drawToShow?.round_number,
                                matches: groupMatchesData,
                            }
                        } else {
                            updatedGroupMatches = [
                                ...prev.groupMatches,
                                {
                                    round_number: drawToShow?.round_number,
                                    matches: groupMatchesData,
                                },
                            ]
                        }
                        updatedGroupMatches.sort(
                            (a, b) => a?.round_number - b?.round_number
                        )
                        return {
                            groupMatches: updatedGroupMatches,
                            drawToShow: { ...rest },
                            tournament:
                                prev?.tournament?.id === tournament?.id
                                    ? prev?.tournament
                                    : tournament,
                        }
                    })
                }
            } catch (error) {
                console.error(error)
            }
        }
    }

    const handleGenerateMatches = async () => {
        try {
            const generatedMatches = await autoGenerateGrandPrixDraw(
                tournament?.attributes?.uuid,
                drawToShow?.gender?.gender_name,
                drawToShow.round_number,
                drawToShow?.subtournament_name,
                drawToShow?.is_double
            )

            if (generatedMatches) {
                handleGetGroupMatches()
                setIsCreateMatchesButtonVisible((prev) => ({
                    ...prev,
                    round4: false,
                }))
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        handleGetGroupMatches()
    }, [
        drawToShow?.round_number,
        drawToShow?.subtournament_name,
        drawToShow?.is_double,
        drawToShow?.gender?.gender_name,
    ])

    useEffect(() => {
        if (
            +updateMatches?.roundToUpdate % 2 === 0 &&
            +drawToShow?.round_number === +updateMatches?.roundToUpdate
        ) {
            handleGetGroupMatches()
        }
    }, [updateMatches?.change])

    useEffect(() => {
        const roundNumber = +drawToShow?.round_number

        if (roundNumber === 2) {
            setIsCreateMatchesButtonVisible((prev) => ({
                ...prev,
                round3: hasAllLoserParticipantUUID(groupMatches),
            }))
        }

        if (roundNumber === 3) {
            setIsCreateMatchesButtonVisible((prev) => ({
                ...prev,
                round4: hasAllLoserParticipantUUID(groupMatches),
            }))
        }
    }, [
        groupMatches,
        drawToShow?.round_number,
        setIsCreateMatchesButtonVisible,
        totalPlayers,
    ])

    const renderButton = () => {
        const roundNumber = Number(drawToShow?.round_number)
        const isVisible =
            (roundNumber === 3 &&
                isCreateMatchesButtonVisible?.round3 &&
                groupMatches?.length === 0) ||
            (roundNumber === 4 &&
                isCreateMatchesButtonVisible?.round4 &&
                !hasAllMatches()) ||
            (roundNumber === 1 && groupMatches?.length === 0)
        return (
            isVisible && (
                <Button onClick={handleGenerateMatches}>
                    {roundNumber === 4
                        ? 'Kreiraj ostale mečeve'
                        : 'Kreiraj mečeve'}
                </Button>
            )
        )
    }

    return (
        <Row>
            <Col>
                <Row>
                    <Col>
                        {user?.role?.type !== 'competitor' && renderButton()}
                    </Col>
                </Row>

                <Row className="gx-5 gy-3 mt-4">
                    {groupMatches?.map((match, index) => (
                        <Col xs={12} md={4} key={index}>
                            <TournamentMatchCard
                                matchDetails={{
                                    round_number:
                                        match?.attributes?.round_number,
                                    tournament_uuid:
                                        match?.attributes?.tournament_uuid,
                                    match,
                                    ...drawToShow,
                                }}
                                onChange={() => {
                                    handleGetGroupMatches()
                                    setUpdateMatches((prev) => ({
                                        roundToUpdate:
                                            +drawToShow?.round_number + 1,
                                        change: !prev?.change,
                                    }))
                                }}
                                onDelete={() => {
                                    handleGetGroupMatches()
                                    setUpdateMatches((prev) => ({
                                        roundToUpdate:
                                            +drawToShow?.round_number + 1,
                                        change: !prev?.change,
                                    }))
                                }}
                            />
                        </Col>
                    ))}
                </Row>
            </Col>
        </Row>
    )
}

export default GroupMatches
